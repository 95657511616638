import { useStaticQuery, graphql } from 'gatsby'

const useContact = () => {
    const { allPrismicContact } = useStaticQuery(graphql`
        query {
            allPrismicContact {
                edges {
                    node {
                        data {
                            contact_group {
                                title
                                link {
                                    link_type
                                    target
                                    url
                                }
                            }
                            location_group {
                                title
                                link {
                                    link_type
                                    target
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    return allPrismicContact.edges[0].node.data
}

export default useContact

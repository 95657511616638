import React from 'react'

const Logo = () => (
	<div>
		<svg
			width="66"
			height="28"
			viewBox="0 0 66 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="fill-current text-black"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.84835 20.7399C6.84835 21.3269 7.32925 21.8021 7.92135 21.8021H11.1285C15.0824 21.8021 17.0799 18.048 17.0799 13.9702C17.0799 9.85154 15.0824 6.01634 11.1285 6.01634H7.92135C7.32925 6.01634 6.84835 6.4921 6.84835 7.07857V20.7399ZM0 1.06301C0 0.475989 0.480903 0.000223624 1.07356 0.000223624H11.1285C19.2401 0.000223624 23.8871 6.05659 23.8871 13.9702C23.8871 21.843 19.2401 27.8183 11.1285 27.8183H1.07356C0.480903 27.8183 0 27.3431 0 26.756V1.06301ZM33.1405 17.1851V21.0097C33.1405 21.5146 33.5531 21.9238 34.0628 21.9238H37.5019C39.6214 21.9238 40.6408 20.4702 40.6408 19.0575C40.6408 17.7649 39.7439 16.2711 37.4613 16.2711H34.0628C33.5531 16.2711 33.1405 16.6803 33.1405 17.1851ZM36.8088 10.7397C38.1951 10.7397 39.1732 9.85131 39.1732 8.43855C39.1732 6.98498 38.5208 5.8948 36.8907 5.8948H34.0628C33.5531 5.8948 33.1405 6.30403 33.1405 6.80831V9.82615C33.1405 10.331 33.5531 10.7397 34.0628 10.7397H36.8088ZM43.6871 12.4331C43.327 12.6427 43.3687 13.1509 43.7599 13.2952C46.6363 14.3574 47.8555 17.2483 47.8555 19.5819C47.8555 23.6194 45.451 27.8186 38.6026 27.8186H27.215C26.7053 27.8186 26.2922 27.4094 26.2922 26.9045V0.914074C26.2922 0.409237 26.7053 0 27.215 0H37.787C43.0052 0 46.3885 2.98765 46.3885 7.46969C46.3885 8.80977 45.7975 11.2026 43.6871 12.4331ZM55.6647 27.2423C55.5094 27.6661 55.1979 27.8198 54.8485 27.8198H50.6502C50.145 27.8198 49.7956 27.3195 50.0287 26.7419L60.3676 0.601108C60.5228 0.177336 60.7565 0.0230335 61.1064 0.0230335H65.3042C65.8099 0.0230335 66.1593 0.523957 65.9262 1.10147L55.6647 27.2423Z"
			/>
		</svg>
	</div>
)

export default Logo

import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Fade from 'react-reveal/Fade'

import Logo from './Logo'
import { Element } from 'react-scroll'

import useContact from '../hooks/useContact'

const Footer = ({ siteTitle }) => {
	const { contact_group, location_group } = useContact()

	return (
		<Fade>
			<Element name="footer">
				<footer className="container mx-auto px-5 md:px-8 lg:px-20 md:pt-32 pb-16 md:pb-32">
					<div className="flex flex-wrap justify-between items-center lg:items-start lg:justify-start w-full">
						<div className="w-2/12 mb-16">
							<Link to="/">
								<Logo />
							</Link>
						</div>
						<div className="w-full lg:w-10/12 flex flex-wrap">
							<div className="w-full lg:w-6/12 mb-16 md:mb-0">
								{contact_group.map((item, index) => (
									<a
										key={index}
										className="text-2xl mb-4 leading-none block underline text-black"
										href={item.link.url}
									>
										{item.title}
									</a>
								))}
							</div>
							<div className="w-full lg:w-6/12 mb-16 md:mb-0">
								{location_group.map((item, index) => (
									<a
										key={index}
										className="text-2xl mb-4 leading-none block underline text-black"
										href={item.link.url}
									>
										{item.title}
									</a>
								))}
							</div>
						</div>
					</div>
				</footer>
			</Element>
		</Fade>
	)
}

Footer.propTypes = {
	siteTitle: PropTypes.string,
}

Footer.defaultProps = {
	siteTitle: ``,
}

export default Footer

import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Fade from 'react-reveal/Fade'
import {
    Link as ScrollLink,
    Element,
    Events,
    animateScroll as scroll,
    scrollSpy,
    scroller,
} from 'react-scroll'
import Logo from './Logo'

import useContact from '../hooks/useContact'

const Header = ({ siteTitle }) => {
    const { contact_group, location_group } = useContact()

    return (
        <Fade>
            <header className="container mx-auto px-5 md:px-8 lg:px-20">
                <div className="flex flex-wrap justify-between items-center lg:items-start lg:justify-start py-8 lg:py-12 w-full">
                    <div className="w-2/12">
                        <Link to="/">
                            <Logo />
                        </Link>
                    </div>
                    <div className="w-8/12 hidden lg:flex flex-wrap">
                        <div className="w-6/12">
                            {contact_group.map((item, index) => (
                                <a
                                    key={index}
                                    className="text-2xl mb-4 leading-none block underline text-black"
                                    href={item.link.url}
                                >
                                    {item.title}
                                </a>
                            ))}
                        </div>
                        <div className="w-6/12">
                            {location_group.map((item, index) => (
                                <a
                                    key={index}
                                    className="text-2xl mb-4 leading-none block underline text-black"
                                    href={item.link.url}
                                >
                                    {item.title}
                                </a>
                            ))}
                        </div>
                    </div>

                    <div className="w-6/12 lg:w-2/12 justify-end flex">
                        <ScrollLink
                            className="text-xl whitespace-no-wrap inline-block bg-black text-white cursor-pointer rounded-full px-8 lg:px-12 py-3 lg:py-4"
                            to="footer"
                            smooth={true}
                            offset={150}
                            duration={1000}
                        >
                            Kontakta oss
                        </ScrollLink>
                    </div>
                </div>
            </header>
        </Fade>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
